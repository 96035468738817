/* IMPORT CUSTOM FONT */
@import url('https://fonts.googleapis.com/css?family=Inter:100,300,400,500,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
}

body {
  background-color: #000;
}

/* Truncate 2 lines utility */
.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.3;
  height: 2.1rem;
}

.bg-button-orbix{

background-color: #0B1F54;
color: #222222;
font-weight: bold;
}

.bg-button-orbix-20{
  background-color: #5acce6;
}


.wrapper-radio-agree-disagree{
  display: inline-flex;
  /* background: #fff; */
  /* height: 100px; */
  /* width: 400px; */
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  /* padding: 20px 15px; */
  /* box-shadow: 5px 5px 30px rgba(0,0,0,0.2); */
}
.wrapper-radio-agree-disagree .inspection_status{
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 12px 0 0;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px 10px;
  border: 2px solid lightgrey;
  transition: all 0.3s ease;
}
.wrapper-radio-agree-disagree .inspection_status .dot{
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.wrapper-radio-agree-disagree .inspection_status-1 .dot::before{
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #198754;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

.wrapper-radio-agree-disagree .inspection_status-2 .dot::before{
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #dc3545;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"]{
  display: none;
}
#inspection_status-1:checked:checked ~ .inspection_status-1
{
  border-color: #24920d;
  background: #198754;
}
#inspection_status-2:checked:checked ~ .inspection_status-2{
  border-color: #c72a39;
  background: #dc3545;
}
#inspection_status-1:checked:checked ~ .inspection_status-1 .dot,
#inspection_status-2:checked:checked ~ .inspection_status-2 .dot{
  background: #fff;
}
#inspection_status-1:checked:checked ~ .inspection_status-1 .dot::before,
#inspection_status-2:checked:checked ~ .inspection_status-2 .dot::before{
  opacity: 1;
  transform: scale(1);
}
.wrapper-radio-agree-disagree .inspection_status span{
  font-size: 15px;
  color: #808080;
  padding-left: 3px;
}
#inspection_status-1:checked:checked ~ .inspection_status-1 span,
#inspection_status-2:checked:checked ~ .inspection_status-2 span{
  color: #fff;
  padding-left: 3px;
}

#l2_decision-accept:checked ~ .l2_decision-accept {
  border-color: #24920d;
  background: #198754;
}
#l2_decision-reject:checked ~ .l2_decision-reject {
  border-color: #c72a39;
  background: #dc3545;
}
#l2_decision-accept:checked ~ .l2_decision-accept .dot,
#l2_decision-reject:checked ~ .l2_decision-reject .dot {
  background: #fff;
}
#l2_decision-accept:checked ~ .l2_decision-accept .dot::before,
#l2_decision-reject:checked ~ .l2_decision-reject .dot::before {
  opacity: 1;
  transform: scale(1);
}
.wrapper-radio-agree-disagree .l2_decision span {
  font-size: 15px;
  color: #808080;
  padding-left: 3px;
}
#l2_decision-accept:checked ~ .l2_decision-accept span,
#l2_decision-reject:checked ~ .l2_decision-reject span {
  color: #fff;
  padding-left: 3px;
}



.text-white{

  color: #f7fafc;
}


.custom-text-gray-700{
  color:#4a5568;
}

.load-report-bg-color{
  background-color: #FF5D00;
  /* opacity: 50%; */
  border: solid 1px #FF5D00;
  font-size: 15px;
  /* color: #B7C2D0; */
  background-color: #FF5D00;
  color: white; /* White text */
  padding: 1px 6px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px; /* Rounded corners */
}

.load-report-bg-color-tracked{
  background-color: #FF5D00;
  color: white; /* White text */
  padding: 1px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px; /* Rounded corners */

}

/* Base class for the button, hidden by default */
.report-open-button {
  display: none; /* Hidden by default */
  border: none;
  color: white; /* White text */
  padding: 1px 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 3px; /* Rounded corners */
}

/* Modify these classes to control when the button should be visible */
.report-open-button-tracked,
.report-open-button-untracked {
  display: block; /* This will make the button visible */
}

.report-open-button-tracked {
  background-color: #00bc8c; /* Green background for tracked */
}

.report-open-button-untracked {
  background-color: #f44336; /* Red background for untracked */
  display: none;
}

.icon-checkmark {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px dotted white; /* Adjust the color as needed */
  border-radius: 50%; /* Makes the border rounded into a circle */
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin-right: 5px; /* Space between the icon and text */
}

.icon-checkmark::before {
  content: "\2713"; /* Unicode checkmark */
  color: white;
  font-weight: bold;
  /* Adjust font-size if necessary to fit the circle */
  font-size: 19px; /* Adjust as needed */
}


.icon-circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 20px; /* Circle size */
  height: 20px; /* Circle size */
  border-radius: 50%; /* Round corners to make a circle */
  border: 2px dotted white; /* Adjust the color as needed */
  padding-top: 10px;

}


.bg-load-report-dark{

  background-color: #a37f06;
}

.bg-custom-gray{
  background-color: #ECECEC;
}

.orbix-text-green-1{
  color: #2F8142;
  font-weight: bold;
}

.orbix-text-green-2{
  color: #2F8142;
  font-weight: bold;
  fill: #fff !important;
  stroke: #fff !important;
}
.text-delete-1{
  color: red;
}


.border-1{
  color: #1EE0AC;
}


.text-yellow-1{
  color: #fffff0;
}

.bg-yellow-1{
  /* background-color: #F4BD0E; */
  border: 1px solid #fffff0;
  color: #fffff0;
  border-radius: 3px;
}

.bg-green-1{
  /* background-color: #F4BD0E; */
  border: 1px solid #00bc8c;
  color: #00bc8c;
  border-radius: 3px;
}

.border-yellow-1{
  color: #fffff0;
  border: #fffff0;
}


.border-orange-1{
  border: 1px solid #FF5D00;
  color: #fff;
  border-radius: 3px;
}

.border-orange-2{
  border: 1px solid #FF5D00;
  color: #fff;
  border-radius: 4px;
}


.bg-orange-1{
  background-color: #FF5D00;
  color: white; /* White text */
  border-radius: 3px;
}
